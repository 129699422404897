import './experience.styles.scss';
import 'aos/dist/aos.css';

import React, { FC, useEffect } from 'react';
import AOS from 'aos';

const Experience: FC = () => {
	useEffect(() => {
		AOS.init({
			duration: 2000,
		});
	}, []);

	return (
		<section id='experience' className='experience-section'>
			<div className='experience-container'>
				<h1 className='section-title'>Experience</h1>
				<div className='experience-timeline'>
					<div className='experience-timeline-item' data-aos='fade-up'>
						<span className='experience-year'>2015-2018</span>
						<div className='experience-circle'></div>
						<div className='experience-content'>
							<h3 className='experience-title'>Edge Hill University</h3>
							<p className='experience-role'>BSc in Computing</p>
							<p className='experience-description'>
								Achieved a <b>First Class Honours</b> in Computing, focusing on
								a wide range of areas such as software development, database
								systems, web development, and computer systems and networks.
								This comprehensive education significantly enhanced my
								programming, problem-solving, and analytical skills, forming a
								strong foundation for my growth as a developer.
							</p>
						</div>
					</div>
					<div className='experience-timeline-item' data-aos='fade-up'>
						<span className='experience-year'>2018-2022</span>
						<div className='experience-circle'></div>
						<div className='experience-content'>
							<h3 className='experience-title'>JD Sports Fashion PLC</h3>
							<p className='experience-role'>
								Graduate Software Developer&nbsp;&nbsp;|&nbsp;&nbsp;Junior
								Developer
							</p>
							<p className='experience-description'>
								As a Graduate Software Developer, my focus was on learning the
								primary development technologies and enhancing existing software
								applications. I played a critical role in code refactoring and
								troubleshooting, in addition to testing programs during the
								development process.
								<br />
								<br />
								Following my promotion to Junior Developer, I assumed
								responsibility for the development and optimisation of software
								programs, ensuring their functionality and usability. This also
								included end-to-end testing, troubleshooting, and collaborating
								with team members to align on development timelines. I worked
								closely with end users to ensure products met their
								expectations, maintaining high-quality standards throughout all
								stages of development.
							</p>
							<p className='experience-description'>
								<b>Skills:</b> HTML, CSS, jQuery, Java, JavaScript, MySQL, and
								Spring Framework.
							</p>
						</div>
					</div>
					<div className='experience-timeline-item' data-aos='fade-up'>
						<span className='experience-year'>2022-2023</span>
						<div className='experience-circle'></div>
						<div className='experience-content'>
							<h3 className='experience-title'>Orka Technology Group</h3>
							<p className='experience-role'>
								Junior Software Engineer&nbsp;&nbsp;|&nbsp;&nbsp;Software
								Engineer
							</p>
							<p className='experience-description'>
								Starting as a Junior Software Engineer at Orka Technology Group,
								I focused on learning and understanding the Orka product suite,
								with a particular emphasis on Orka Check. This period was
								instrumental in building a solid foundation in various technical
								aspects.
								<br />
								<br />
								Following this, I advanced to the role of Software Engineer. In
								this capacity, my responsibilities included designing and
								implementing scalable applications, contributing to system
								architecture, and engaging in technical analysis. The role also
								involved collaborating with Product Owners, writing testable
								code, maintaining solutions, and participating in Release
								Management.
							</p>
							<p className='experience-description'>
								<b>Skills:</b> Docker, Figma, GitHub, MySQL, React, Redux, SASS,
								TypeScript.
							</p>
						</div>
					</div>
					<div className='experience-timeline-item' data-aos='fade-up'>
						<span className='experience-year present'>2023-Present</span>
						<div className='experience-circle'></div>
						<div className='experience-content'>
							<h3 className='experience-title'>Interactive Investor</h3>
							<p className='experience-role'>App Developer</p>
							<p className='experience-description'>
								In my current role at Interactive Investor, I am responsible for
								developing and enhancing mobile/web applications, ensuring they
								are user-friendly, efficient, and scalable. My focus includes
								building intuitive UI/UX designs, integrating APIs, and
								collaborating with cross-functional teams to implement new
								features.
							</p>
							<p className='experience-description'>
								<b>Skills:</b> ChakraUI, CSS, Docker, Drupal, Figma, GitHub,
								Next.js, React, React Native, Storybook, TypeScript.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Experience;
